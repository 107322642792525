import { Configuration } from './contexts/ConfigProvider/ConfigProvider';

const config: Configuration = {
  backendUrl: 'https://be.kendrickheller.com/',
  backendWs: 'wss://ws.kendrickheller.com/pgws',
  clientId: '854460595723-dq4l43dqp697074hc9esv06pf5qgo3m3.apps.googleusercontent.com',
  defaultAvatar: 'https://rs.kendrickheller.jp/images/fcm.png',
};

export default config;
